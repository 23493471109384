import { useGomakeAxios, useSnackBar } from "@/hooks";
import {
  getAndSetClientAddress,
  getAndSetClientContacts,
} from "@/services/hooks";
import {
  IContactData,
  addressSelectState,
  clientAddressState,
  clientContactsState,
  quoteConfirmationState,
  quoteItemState,
} from "@/store";
import { useCallback, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { currencyUnitState } from "@/store/currency-units";
import { DOCUMENT_TYPE } from "../quotes/enums";
import { useRouter } from "next/router";
import { EHttpMethod } from "@/services/api-service/enums";
import {
  addressModalState,
  EAddressType,
} from "@/widgets/quote-new/business-widget/address-widget/state";
import {
  addDocumentAddressApi,
  getDocumentApi,
  updateDocumentAddressApi,
} from "@/services/api-service/generic-doc/documents-api";
import { v4 as uuidv4 } from "uuid";
import {
  getClientPaymentItemsApi,
  getReceiptByIdApi,
} from "@/services/api-service/generic-doc/receipts-api";
import { getAllShipmentTypesApi } from "@/services/api-service/shipmentTypes/shipment-type-api";

const useQuoteGetData = (docType?: DOCUMENT_TYPE) => {
  const { callApi } = useGomakeAxios();
  const router = useRouter();
  const {
    alertSuccessUpdate,
    alertSuccessAdded,
    alertFaultAdded,
    alertFaultGetData,
    alertRequiredFields,
  } = useSnackBar();
  const [clientAddressValue, setClientAddressValue] =
    useRecoilState<any>(clientAddressState);
  const setClientContactsValue =
    useSetRecoilState<IContactData[]>(clientContactsState);
  const currenciesUnits = useRecoilValue<any>(currencyUnitState);
  const addressSelect = useRecoilValue(addressSelectState);
  const quoteConfirm = useRecoilValue<any>(quoteConfirmationState);
  const [shipmentTypes, setShipmentTypes] = useState<
    { label: string; value: string; price: any }[]
  >([]);
  const [quoteItemValue, setQuoteItemValue] =
    useRecoilState<any>(quoteItemState);
  const setOpenModal = useSetRecoilState<boolean>(addressModalState);
  const [requestAbortController, setRequestAbortController] =
    useState<AbortController>(null);
  const getAllClientContacts = useCallback(async () => {
    if (quoteItemValue?.customerID) {
      await getAndSetClientContacts(callApi, setClientContactsValue, {
        ClientId: quoteItemValue?.customerID,
      });
    }
  }, [quoteItemValue?.customerID, quoteConfirm]);

  const getAllClientAddress = useCallback(async () => {
    if (quoteItemValue?.customerID) {
      return await getAndSetClientAddress(callApi, setClientAddressValue, {
        ClientId: quoteItemValue?.customerID,
      });
    }
  }, [quoteItemValue?.customerID]);

  const getAllShipmentTypes = async (includeSelfPickup = false) => {
    const callBack = (res) => {
      if (res?.success) {
        let types = res?.data.map((item) => ({
          value: item.id,
          label: item.title,
          price: item?.initialPrice,
        }));

        // Add "Self Pickup" only if the prop is true
        if (includeSelfPickup) {
          types.push({
            value: "self_pickup",
            label: "Self Pickup",
            price: 0, // Assuming there's no price for self-pickup
          });
        }

        setShipmentTypes(types);
      }
    };

    await getAllShipmentTypesApi(callApi, callBack);
  };

  const getCurrencyUnitText = (currency) => {
    const foundCurrency = currenciesUnits?.find((c) => c.value === currency);
    if (foundCurrency) {
      return foundCurrency.text;
    } else {
      return "";
    }
  };

  // in case of an error all these routes have been moved from use-quote.tsx to current hook (use-quote-get-data.tsx)

  const getQuote = useCallback(async () => {
    if (requestAbortController) {
      requestAbortController.abort();
    }

    try {
      if (
        router?.query?.isNewCreation &&
        docType === DOCUMENT_TYPE.receipt &&
        router?.query?.documentNumber &&
        router?.query?.ClientId
      ) {
        return; // No need to call anything if opening receipt page from pay option
      }

      if (router?.query?.isNewCreation && docType === DOCUMENT_TYPE.receipt) {
        const callBack = (res) => {
          if (res?.success) {
            const _data = res?.data || {};
            setQuoteItemValue(_data);
          } else {
            alertFaultGetData();
          }
        };
        await getClientPaymentItemsApi(callApi, callBack);
      } else if (router?.query?.isNewCreation) {
        const requestBody = {
          documentType: docType,
          ...(router?.query.orderId && { orderId: router.query.orderId }),
          ...(router?.query.deliveryNoteId && {
            deliveryNoteID: router.query.deliveryNoteId,
          }),
          ...(router?.query.documentToDuplicateId && {
            documentToDuplicateId: router.query.documentToDuplicateId,
          }),
          ...(router?.query.documentId && {
            documentId: router.query.documentId,
          }),
          ...(router?.query.budgetId && { budgetId: router.query.budgetId }),
        };

        const res = await callApi(
          EHttpMethod.POST,
          `/v1/erp-service/documents/get-new-document-data`,
          requestBody
        );

        if (res?.success) {
          const _data = res?.data?.data?.result || {};
          setQuoteItemValue(_data);
        } else {
          alertFaultGetData();
        }
      } else if (docType === DOCUMENT_TYPE.receipt) {
        const isOutgoingPayments = router?.query?.isOutgoingPayments === "true";
        const supplierPaymentId = router?.query?.id;

        const callBack = (res) => {
          if (res?.success) {
            const _data = res?.data || {};
            setQuoteItemValue(_data);
          } else {
            alertFaultGetData();
          }
        };
        await getReceiptByIdApi(callApi, callBack, {
          receiptId: router?.query?.Id ,
        });
      } else {
        const newRequestAbortController = new AbortController();
        setRequestAbortController(newRequestAbortController);

        const callBack = (res) => {
          if (res?.success) {
            let indexs = 0;
            const _data = res?.data || {};
            const mapData = _data?.documentItems?.map((item, index) => {
              indexs++;
              const parentIndex = indexs;
              const _childsDocumentItemsMapping =
                item?.childsDocumentItems?.map((child, index2) => {
                  indexs++;
                  return {
                    id: indexs,
                    amount: child?.quantity,
                    unitPrice: child?.price,
                    discount: child?.discount,
                    finalPrice: child?.finalPrice,
                    quoteItemId: child?.id,
                  };
                });
              return {
                id: parentIndex,
                itemName: item?.productName,
                details: (
                  <div
                    style={
                      _childsDocumentItemsMapping
                        ? {
                            height: "100%",
                            overflowY: "scroll",
                            paddingRight: 5,
                          }
                        : { height: 36, overflowY: "scroll", paddingRight: 5 }
                    }
                  >
                    {item?.content}
                  </div>
                ),
                amount: item?.quantity,
                unitPrice: item?.price,
                discount: item?.discount,
                finalPrice: item?.finalPrice,
                quoteItemId: item?.id,
                childsDocumentItems: _childsDocumentItemsMapping,
              };
            });
            _data.documentItemsMapping = mapData;
            setQuoteItemValue(_data);
          } else {
            alertFaultGetData();
          }
        };

        await getDocumentApi(
          callApi,
          callBack,
          {
            documentType: docType,
            Id: router?.query?.Id,
          },
          false,
          newRequestAbortController
        );
      }
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Request aborted.");
      } else {
        alertFaultGetData();
      }
    }
  }, [callApi, docType, requestAbortController, router, setQuoteItemValue]);

  // const getQuote = async () => {
  //   if (
  //     router?.query?.isNewCreation &&
  //     docType === DOCUMENT_TYPE.receipt &&
  //     router?.query?.documentNumber &&
  //     router?.query?.ClientId
  //   ) {
  //     return; // No need to call anything , in case we open receipt page from pay option from invoice list
  //   }
  //   if (router?.query?.isNewCreation && docType === DOCUMENT_TYPE.receipt) {
  //     const callBack = (res) => {
  //       if (res?.success) {
  //         const _data = res?.data || {};
  //         setQuoteItemValue(_data);
  //       } else {
  //         alertFaultGetData();
  //       }
  //     };
  //     await getClientPaymentItemsApi(callApi, callBack);
  //   } else if (router?.query?.isNewCreation) {
  //     const requestBody: any = {
  //       documentType: docType,
  //     };
  //     if (router?.query.orderId) {
  //       requestBody.orderId = router.query.orderId;
  //     }
  //     if (router?.query.deliveryNoteId) {
  //       requestBody.deliveryNoteID = router.query.deliveryNoteId;
  //     }
  //     if (router?.query.documentToDuplicateId) {
  //       requestBody.documentToDuplicateId = router.query.documentToDuplicateId;
  //     }
  //     if (router?.query.documentId) {
  //       requestBody.documentId = router.query.documentId;
  //     }
  //     if (router?.query.budgetId) {
  //       requestBody.budgetId = router.query.budgetId;
  //     }
  //     const res = await callApi(
  //       EHttpMethod.POST,
  //       `/v1/erp-service/documents/get-new-document-data`,
  //       requestBody
  //     );

  //     if (res?.success) {
  //       const _data = res?.data?.data?.result || {};
  //       setQuoteItemValue(_data);
  //     } else {
  //       alertFaultGetData();
  //     }
  //   } else if (docType === DOCUMENT_TYPE.receipt) {
  //     const callBack = (res) => {
  //       if (res?.success) {
  //         const _data = res?.data || {};
  //         setQuoteItemValue(_data);
  //       } else {
  //         alertFaultGetData();
  //       }
  //     };
  //     await getReceiptByIdApi(callApi, callBack, {
  //       receiptId: router?.query?.Id,
  //     });
  //   } else {
  //     const callBack = (res) => {
  //       if (res?.success) {
  //         let indexs = 0;
  //         const _data = res?.data || {};
  //         const mapData = _data?.documentItems?.map(
  //           (item: any, index: number) => {
  //             indexs++;
  //             const parentIndex = indexs;
  //             const _childsDocumentItemsMapping =
  //               item?.childsDocumentItems?.map((child: any, index2: number) => {
  //                 indexs++;
  //                 return {
  //                   id: indexs,
  //                   amount: child?.quantity,
  //                   unitPrice: child?.price,
  //                   discount: child?.discount,
  //                   finalPrice: child?.finalPrice,
  //                   quoteItemId: child?.id,
  //                 };
  //               });
  //             return {
  //               id: parentIndex,
  //               itemName: item?.productName,
  //               details: (
  //                 <div
  //                   style={
  //                     _childsDocumentItemsMapping != null
  //                       ? {
  //                           height: "100%",
  //                           overflowY: "scroll",
  //                           paddingRight: 5,
  //                         }
  //                       : { height: 36, overflowY: "scroll", paddingRight: 5 }
  //                   }
  //                 >
  //                   {item?.content}
  //                 </div>
  //               ),
  //               amount: item?.quantity,
  //               unitPrice: item?.price,
  //               discount: item?.discount,
  //               finalPrice: item?.finalPrice,
  //               quoteItemId: item?.id,
  //               childsDocumentItems: _childsDocumentItemsMapping,
  //             };
  //           }
  //         );
  //         _data.documentItemsMapping = mapData;
  //         setQuoteItemValue(_data);
  //       } else {
  //         alertFaultGetData();
  //       }
  //     };
  //     await getDocumentApi(callApi, callBack, {
  //       documentType: docType,
  //       Id: router?.query?.Id,
  //     });
  //   }
  // };

  const onClickAddNewAddress = useCallback(
    async (item: any, isUpdate: boolean, addressType: EAddressType) => {
      if (!item?.addressId || !item?.city) {
        alertRequiredFields();
        return;
      }
      const res = await callApi(
        EHttpMethod.POST,
        `/v1/crm-service/customer/create-address`,
        {
          address1: item?.addressId,
          street: item?.street,
          city: item?.city,
          entry: item?.entry,
          type: addressType,
          apartment: item?.apartment,
          clientId: quoteItemValue?.customerID,
          floor: item?.floor,
          postbox: item?.postbox,
          zipCode: item?.zipCode,
        }
      );
      if (res?.success) {
        alertSuccessAdded();
        const result = await getAllClientAddress();
        isUpdate
          ? updateClientAddress(
              result.find((item) => item.id === res.data.data.result),
              addressType
            )
          : onClickAddAddress(
              result.find((item) => item.id === res.data.data.result),
              addressType
            );
      } else {
        alertFaultAdded();
      }
    },
    [quoteItemValue, router]
  );

  const onClickAddAddress = async (item: any, addressType: EAddressType) => {
    if (!item?.city) {
      alertRequiredFields();
      return;
    }

    if (router.query.isNewCreation) {
      const updatedQuoteItemValue = { ...quoteItemValue };
      const addressArray =
        addressType === EAddressType.BILLING
          ? "documentBillingAddresses"
          : "documentDeliveryAddresses";

      if (!Array.isArray(updatedQuoteItemValue[addressArray])) {
        updatedQuoteItemValue[addressArray] = [];
      }

      const newAddress = {
        id: uuidv4(),
        addressID: item?.addressId,
        street: item?.street,
        city: item?.city,
        entry: item?.entry,
        type: addressType,
        apartment: item?.apartment,
        notes: "",
        documentID: updatedQuoteItemValue?.id,
        floor: item?.floor,
        postbox: item?.postbox,
        zipCode: item?.zipCode,
      };

      updatedQuoteItemValue[addressArray] = [
        ...updatedQuoteItemValue[addressArray],
        newAddress,
      ];

      setQuoteItemValue(updatedQuoteItemValue);
      setOpenModal(false);
    } else {
      const callBack = (res) => {
        if (res.success) {
          alertSuccessAdded();
          getQuote();
          setOpenModal(false);
        } else {
          alertFaultAdded();
        }
      };

      await addDocumentAddressApi(callApi, callBack, {
        documentType: docType,
        address: {
          addressID: item?.id,
          street: item?.street,
          city: item?.city,
          entry: item?.entry,
          type: addressType,
          apartment: item?.apartment,
          notes: item?.notes || "",
          documentID: quoteItemValue?.id,
          floor: item?.floor,
          postbox: item?.postbox,
          zipCode: item?.zipCode,
        },
      });
    }
  };

  const updateClientAddress = async (item: any, addressType: EAddressType) => {
    if (!item?.city) {
      alertRequiredFields();
      return;
    }

    if (router.query.isNewCreation) {
      const updatedQuoteItemValue = { ...quoteItemValue };
      const addressArray =
        addressType === EAddressType.BILLING
          ? "documentBillingAddresses"
          : "documentDeliveryAddresses";

      const updatedAddresses = updatedQuoteItemValue[addressArray].map(
        (address) => {
          if (address.id === item.id) {
            return {
              ...address,
              street: item.street,
              city: item.city,
              entry: item.entry,
              apartment: item.apartment,
              notes: item.notes || "",
              floor: item?.floor,
              postbox: item?.postbox,
              zipCode: item?.zipCode,
            };
          }
          return address;
        }
      );

      updatedQuoteItemValue[addressArray] = updatedAddresses;
      setQuoteItemValue(updatedQuoteItemValue);
      setOpenModal(false);
    } else {
      const callBack = (res) => {
        if (res?.success) {
          alertSuccessUpdate();
          getQuote();
          setOpenModal(false);
        } else {
          alertFaultAdded();
        }
      };

      const addressArray =
        addressType === EAddressType.BILLING
          ? "documentBillingAddresses"
          : "documentDeliveryAddresses";

      await updateDocumentAddressApi(callApi, callBack, {
        documentType: docType,
        address: {
          id: quoteItemValue?.[addressArray]?.[0]?.id,
          addressID: quoteItemValue?.[addressArray]?.[0]?.addressID,
          street: item?.street,
          city: item?.city,
          entry: item?.entry,
          apartment: item?.apartment,
          notes: item?.notes || "",
          documentID: quoteItemValue?.id,
          type: addressType,
          floor: item?.floor,
          postbox: item?.postbox,
          zipCode: item?.zipCode,
        },
      });
    }
  };

  return {
    getQuote,
    quoteItemValue,
    clientAddressValue,
    addressSelect,
    getAllClientAddress,
    getAllClientContacts,
    getCurrencyUnitText,
    getAllShipmentTypes,
    shipmentTypes,
    updateClientAddress,
    onClickAddAddress,
    onClickAddNewAddress,
  };
};

export { useQuoteGetData };
